.header_top_panel {
  /* position: absolute; */
  height: 80px;
  width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  color: white;
}

.header_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.header_top_panel p {
  margin: 0px;
}

.profile_photo {
  background-color: rgb(221, 176, 118);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 20px;
}

.logo_block {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.logo_image {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background-color: aliceblue;
}

.logo {
  font-size: 1.2rem;
  font-weight: 300;
}

.user_button {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  /* border-radius: 50%; */
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
  margin-right: 20px;
  padding: 10px;
  z-index: 40;

  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 30px;
  padding: 5px;
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
}

@media (max-width: 900px) {
  .header_top_panel {
    position: absolute;
  }
  .logo_block {
    opacity: 0;
  }
}
