.interface_container {
  background-color: rgba(240, 248, 255, 0.188);
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.stream {
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* background-color: aliceblue; */
}

.interface_hud {
  position: absolute;
  width: 25vw;
  opacity: 0.5;

  display: flex;
  align-items: center;
  justify-content: center;
}
