.image_container {
  background-color: rgba(255, 255, 255, 0.288);
  height: 100%;
  transition: 0.2s ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;
}

.image_container::-webkit-scrollbar {
  display: none;
}

.image_grid {
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  transition: 0.2s ease-in-out;
}

.popup {
  position: relative;
}

.images {
  width: 100%;
  padding: 1px;
  transition: 0.2s ease-in-out;
  border-radius: 10px;
  /* border: 1px rgba(255, 255, 255, 0) solid; */
}

.images:hover {
  /* border: 1px white solid; */
  filter: brightness(1.1);
}

.metadata {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  position: absolute;
  top: 0px;
  right: 0px;

  width: auto;
  padding: 20px;
  margin: 20px;
  padding-top: 50px;
  color: rgb(0, 0, 0);

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.metadata_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 150px;
}

.metadata_button {
  width: auto;
}

.metadata_icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
}
