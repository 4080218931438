.data_container {
  /* background-color: rgb(255, 240, 253); */
  width: 100vw;
  height: 90vh;

  display: flex;
  flex-direction: row;
}

.left_container {
  margin: 20px;
  width: 100vw;
  border-radius: 30px;
  overflow: hidden;
}

/* Farm Info */

.farm_container {
  /* background-color: rgba(255, 255, 255, 0.2); */
  /* position: relative; */
  margin: 20px;
  width: 30vw;
  border-radius: 30px 0px 0px 30px;
  /* padding-bottom: 50px; */
  color: white;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  transition: 0.2s ease-in-out;
}

.farm_container::-webkit-scrollbar {
  display: none;
}

.farm_name {
  margin: 10px 0 30px 0;
  font-size: 2rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.farm_info {
  font-size: 1rem;
  margin: 0px;
}

/* Selector */
.select_container {
  background-color: rgba(250, 235, 215, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 10px;
}

.selector_type {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.selector {
  padding: 10px;
  border-radius: 10px;
}

.add_button {
  height: 40px;
  width: 50px;
  background-color: teal;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* New Farm */
.new_container {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 40px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.new_farm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new_input {
  padding: 10px;
  border-style: none;
  border-radius: 10px;
}

.new_comment {
  padding: 10px;
  border-style: none;
  border-radius: 10px;
  resize: vertical;
}

.upload_icon {
  filter: invert(100%);
  transform: scale(2.5);
}

/* Header */
.header_container {
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 40px;
  /* border-radius: 20px; */
}

.header_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.new_farm_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.upload_icon {
  width: 20px;
  filter: invert(100%);
}

.close_scan_viewer {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 10px;
}

.close_icon {
  width: 30px;
  filter: invert(100%);
}

@media (max-width: 900px) {
  .swipe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swipe_icon {
    background-color: rgba(255, 255, 255, 0.5);
    width: 50px;
    height: 2px;
    border-radius: 1px;
  }
  .data_container {
    margin: 0px;
    padding: 0px;
    height: 100vh;
    flex-direction: column;
  }
  .farm_container {
    background-color: rgb(50, 60, 72);
    margin: 0px;
    height: 800px;
    padding-bottom: 200px;
    width: 100vw;
    border-radius: 20px;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.4);
  }
  .left_container {
    margin: 0px;
    border-radius: 0px;
    height: 100%;
    overflow: scroll;
    width: auto;
  }
}
