.table_container {
  /* background-color: rgba(255, 255, 255, 0.1); */
  width: 100%;
  height: auto;
  padding: 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: scroll;
  gap: 20px;
  overflow: hidden;
}

.show_all {
  color: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
}
