.upload_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
}

.upload_icon {
  width: 50px;
}

.upload_button {
  background-color: teal;
  /* background-color: ; */
  width: 150px;
  z-index: 30;
  pointer-events: all;
}

.upload_button:hover {
  background-color: rgb(7, 146, 146);
}

.upload_button:disabled {
  background-color: rgb(175, 175, 175);
}

input[type="file"] {
  opacity: 1;
  position: relative;
  padding: auto;
  padding: 6vw;
  border-radius: 50px;
}

input::file-selector-button {
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 10px;
  margin-bottom: 10px;
}

.upload_form {
  background-color: rgba(255, 255, 255, 0.918);
  position: relative;
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
  height: 350px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.inner {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  pointer-events: none;
}

.requirements_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;

  padding: 20px;
  margin-left: 120px;
  width: 350px;

  color: black;

  /* border-left: 1px rgba(0, 0, 0, 0.2) solid; */
  /* border-radius: 20px; */
}

.requirements {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

@media (max-width: 800px) {
  .upload_form {
    width: 100%;
    height: 350px;
  }
}
