.app {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
}

.app_map {
  height: 100%;
  width: 100%;
}

.info_window {
  position: relative;
  background-color: rgba(228, 166, 166, 0.9);
  /* width: 200px; */
  /* height: 200px; */
  margin: 0px;
  padding: 10px;
  top: 0px;
}

.info {
  /* width: 100px;
  height: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  overflow: hidden;
}

.info_button {
  width: auto;
  padding: 15px;
  margin: 10px;
}

.timeline_container {
  background-color: rgb(218, 231, 243);
  border-left: 1px black solid;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
}

.timeline {
  height: 100%;
  width: 100%;
  background-color: antiquewhite;
  margin: 5px;
  margin-left: 10px;

  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 10px;
}

.timeline::before {
  content: "";
  position: absolute;

  background-color: rgb(0, 0, 0);
  width: 10px;
  height: 10px;
  /* top: 0px; */
  left: 21px;
  border-radius: 5px;
}

.timeline_column {
  display: flex;
  flex-direction: column;
}

.stat_container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.stat_card {
  background-color: rgba(0, 0, 0, 0.1);
  /* width: 150px;
  height: 150px; */
  border-radius: 10px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  transition: 0.2s ease-in-out;
}

.stat_card:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.stat_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0px;
  margin: 0px;
}

.stat_column {
  display: flex;
  flex-direction: column;
}

.info p {
  margin: 5px;
  font-weight: 400;
  font-size: 0.8rem;
}

.stat_main {
  font-size: 1.8rem !important;
  font-weight: 300;
  margin: 5px;
}

.stat_secondary {
  font-size: 1.1rem;
  margin: 0px;
  color: green;
}

.stat_title {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.8);
  margin: 0px;
}

.info h2 {
  margin: 5px;
  font-weight: 400;
  /* font-size: 1rem; */
  /* padding: 0px; */
}

.stat_icon {
  width: 20px;
  filter: invert(0.5);
}

.warning {
  width: 90%;
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 20px;
  background-color: rgb(241, 101, 93);
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
}

.warning_icon {
  width: 20px;
  fill: white;
  /* filter: invert(1); */
}

.warning_message {
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
}

/* Not working */
.gm_style {
  background-color: rgb(194, 164, 126);
}

.gm-style-iw-t::after {
  background: none;
  box-shadow: none;
}

.line_editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.line_editor button,
input {
  width: 100px;
}

.editor_name {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 20px;
  border: none;
  border-width: 0;
  box-shadow: none;
}
.editor_name {
  border: 0;
}

.editor_name:focus-visible {
  outline: none;
}

.editor_delete {
  background-color: #f1655c;
}

/* New Location */
.new_location {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  overflow: hidden;
}

.new_block {
  width: 100%;
  padding: 10px;
  /* background-color: antiquewhite; */

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.new_block input,
select,
textarea {
  /* width: 80%; */
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.08);

  font-size: 1rem;
  font-weight: 300;

  display: flex;
  align-items: center;
  justify-content: center;
}

.new_block .checkbox {
  accent-color: teal;
}

.new_block textarea {
  resize: none;
  /* height: 80px; */
  /* width: 150px; */
  border: 1px black solid;
  font-family: "Courier New", Courier, monospace;
}

.new_block p {
  margin: 0px;
}

.new_row {
  /* padding: 10px; */
  /* background-color: aliceblue; */
  display: flex;
  flex-direction: row;
  gap: 10px;
}
