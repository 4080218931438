.form_container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(255, 255, 255, 0.9);
}

.inner_container {
  width: max(350px, 25vw);
  height: 400px;
  /* border-radius: 20px; */
  box-sizing: border-box;
  padding: 0px 40px;
  margin: 20vw;
  z-index: 3;
  border-left: 2px solid rgba(255, 255, 255, 0.1);
}

.subtitle {
  margin: 10px 0 20px 0;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}

.title {
  margin: 10px 0 30px 0;
  font-size: 2.5rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 15px;
}

.input {
  border-radius: 30px;
  width: 100%;
  background-color: rgba(63, 78, 95, 0.5);
  border: 1px solid rgba(63, 78, 95, 0.5);
  outline: 0 !important;
  box-sizing: border-box;
  padding: 12px 25px;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.input:focus {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(63, 78, 95, 0.6);
}

.page_link {
  text-decoration: underline;
  margin: 0;
  text-align: end;
  color: rgba(255, 255, 255, 0.9);
  text-decoration-color: #747474;
}

.page_link_label {
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
}

.page_link_label:hover {
  color: rgb(11, 156, 156);
}

.form_btn {
  padding: 15px 20px;
  border-radius: 30px;
  border: 0 !important;
  outline: 0 !important;
  background: teal;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 1.1rem;
}

.form_btn:hover {
  box-shadow: none;
  background: rgb(3, 143, 143);
}

.sign_up_label {
  margin: 40px 10px;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.sign_up_link {
  margin-left: 1px;
  font-size: 1rem;
  text-decoration: underline;
  text-decoration-color: teal;
  color: rgb(5, 139, 139);
  cursor: pointer;
  font-weight: 700;
}

.sign_up_link:hover {
  color: rgb(8, 153, 153);
}

.buttons_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 15px;
}

.google_login_button {
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  gap: 10px;
  transition: 0.2s ease-in-out;
}

.google_login_button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.signout_button {
  width: 200px;
  padding: 15px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  transition: 0.2s ease-in-out;
  background-color: teal;
  color: white;
}

.google_prompt {
  font-size: 1rem;
}

@media (max-width: 800px) {
  .inner_container {
    margin: 5vw;
  }
  .subtitle {
    font-size: 0.8rem;
  }
  .title {
    font-size: 1.5rem;
  }
  .input {
    padding: 10px 15px;
  }
  .form_btn {
    padding: 10px 15px;
  }
}
