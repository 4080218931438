.home_container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(255, 255, 255, 0.9);
}

.home_inner_container {
  width: max(550px, 28vw);
  height: 400px;
  box-sizing: border-box;
  padding: 0px 40px;
  margin: 15vw;
  z-index: 3;
  border-left: 2px solid rgba(255, 255, 255, 0.1);

  display: flex;
  flex-direction: column;
}

.home_title {
  font-size: 3rem;
  font-weight: 400;
}

.home_subtitle {
  font-size: 1.8rem;
  font-weight: 300;
  margin: 5px;
}

.home_button {
  background-color: teal;
  width: 150px;
  border-radius: 30px;
  margin-top: 20px;
  padding: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: 0.2s ease-in-out;
}

.home_button:hover {
  background-color: rgb(10, 151, 151);
}

.main_container {
  position: absolute;
  right: 0px;
  top: 12vh;
  width: 42vw;
  padding: 120px;
}

.main_image {
  position: absolute;
  width: 42vw;
  transition: 0.5s ease-in-out;
  animation: main_image 0.6s ease-in-out;
}

@keyframes main_image {
  from {
    /* transform: translateY(800px); */
    opacity: 0;
  }
  to {
    /* transform: translateY(0px); */
    opacity: 1;
  }
}

.main_image:hover {
  transform: translateY(-5px);
}

.marker {
  position: absolute;
  width: 20px;
  top: 0px;
}

@media (max-width: 1600px) {
  .main_image {
    display: none;
  }
}

@media (max-width: 400px) {
  .home_inner_container {
    margin: 5vw;
    width: 100vw;
  }
  .home_title {
    font-size: 2rem;
  }
  .home_subtitle {
    font-size: 1.2rem;
  }
  .home_button {
    width: 150px;
  }
}
