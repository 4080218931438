.scan_list {
  display: flex;
  flex-direction: column;
  height: 1000px;
  padding-left: 20px;
  border-left: 2px rgba(255, 255, 255, 0.2) solid;
  overflow: visible;
}

.scan_list::-webkit-scrollbar {
  display: none;
}

.scan_container {
  position: relative;
  /* width: 100%; */
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  transition: 0.2s ease-in-out;
}

.scan_container .scan_column_buttons {
  /* Hide options if scan not selected */
  display: none;
}

.scan_container_active {
  position: relative;
  background-color: rgba(202, 219, 245, 0.2);
  border-radius: 20px;
  border: 1px white solid;
  margin: 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.2s ease-in-out;
}

.scan_column_buttons {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.scan_container:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.scan_column {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 50px;
}

.scan_labels {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
}

.scan_info {
  padding: 0px;
  margin-top: 5px;
}

.menu {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 20px;
}

.menu_icon {
  filter: invert();
  width: 20px;
}

.menu_icon_inverted {
  filter: invert(100%);
  width: 20px;
}

.scan_options {
  position: absolute;
  left: -100px;
  top: 12px;
  background-color: rgba(173, 173, 173, 0.479);
  display: flex;
  flex-direction: column;
  border-radius: 5px 15px 5px 15px;
  z-index: 20;
  overflow: hidden;
  cursor: pointer;
}

.option {
  padding: 10px;
  width: 70%;
  transition: 0.2s ease-in-out;
}

/* .option:hover {
  background-color: rgba(216, 216, 216, 0.637);
} */

/* Overriding calendar styles */
.rdrCalendarWrapper {
  background-color: rgba(255, 255, 255, 0.185) !important;
}

/* .rdrDayNumber span { */
/* color: rgba(255, 255, 255, 0.623) !important; */
/* } */

.rdrDateDisplayWrapper {
  background-color: rgba(255, 255, 255, 0.158) !important;
}

/* Filter block styling */
.filter_container {
  /* background-color: rgba(255, 255, 255, 0.1); */
  display: flex;
  flex-direction: column;
}

.comment_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-left: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
}

.comment_container textarea {
  height: 80px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  resize: vertical;
  margin: 10px;
}

.comment_container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter_main {
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  /* border-radius: 20px 20px 0px 0px; */
}

.filter_panel {
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  padding-left: 20px;
  gap: 20px;
  /* border-radius: 20px 20px 0px 0px; */
}

.filter_options {
  height: auto;
  overflow: hidden;
  margin-bottom: 10px;
}

.filter_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 20px;
  /* margin-bottom: 10px; */
  padding-left: 20px;
}

.filter_row_border {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 20px;
  /* margin-bottom: 10px; */
  padding-left: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  transition: 0.2s ease-in-out;
}

.filter_row_border:hover {
  background-color: rgba(216, 216, 216, 0.637);
}

.calendar {
  height: 350px;
  transition: 0.2s ease-in-out;
  overflow: hidden;
}

.filter_icon {
  filter: invert(100%);
  width: 20px;
}

.line_container {
  background-color: rgba(255, 255, 255, 0.1);
  /* height: 100px; */
  overflow: hidden;
}

.clear:hover {
  transition: 0.2s ease-in-out;
  color: teal;
}

.icon {
  position: absolute;
  /* filter: invert(1); */
  /* top: 10px; */
  /* left: -40px; */
}

.instructions {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  margin: 10px;
}

.line_selected {
  margin-left: 20px;
}

.no_scans {
  position: absolute;
  background-color: rgba(240, 248, 255, 0.1);
  /* height: 80%;
  width: 30%; */

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .scan_container_active {
    margin: 10px;
  }
  .scan_list {
    height: 300px;
    padding-bottom: 300px;
    overflow-y: scroll;
  }
}
