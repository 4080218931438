.chart_container {
  /* background-color: rgb(255, 255, 255); */
  height: 100%;
  padding: 20px;
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: scroll;

  /* display: flex;
    flex-direction: column;
    gap: 20px; */
}

.chart_container::-webkit-scrollbar {
  display: none;
}

.individual_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 45%;

  margin-bottom: 40px;
}

.table_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 400px;
  margin-bottom: 40px;
  /* overflow-y: scroll; */
}

.chart {
  width: 45%;
  padding: 10px;
  padding-bottom: 20px;
}

.main_chart {
  /* background-color: rgba(250, 235, 215, 0.336); */
  height: 400px;
  margin-bottom: 80px;
}

.wide_chart {
  height: 50%;
  margin-bottom: 80px;
}

.title {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 1.2rem;

  padding: 0px;
  margin: 5px;
}

.subtitle {
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 1rem;
}

.selector {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  color: white;
  width: 200px;
}

.selector option {
  color: black;
}

.summary {
  background-color: rgba(240, 248, 255, 0.3);
  border-radius: 20px;
  padding: 20px;
  padding-bottom: 40px;
  margin: 0px;
}

.row_main {
  width: 100%;
  display: flex;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  gap: 20px;
}

.data {
  /* width: 160px; */
  height: 120px;
  background-color: rgba(240, 248, 255, 0.2);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;

  transition: 0.2s ease-in-out;
}

.data:hover {
  background-color: rgba(240, 248, 255, 0.3);
}

.main {
  color: white;
  font-weight: 400;
  font-size: 2.5rem;
}

.units {
  color: white;
  font-weight: 400;
  font-size: 1rem;
}

.spacer {
  height: 40px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.distribution {
  background-color: rgba(240, 248, 255, 0.2);
  /* height: 50px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
}

.sub {
  color: white;
  font-weight: 400;
  font-size: 0.8rem;
}

.line {
  background-color: aliceblue;
  height: 40px;
  width: 1px;
}

.chart_mobile {
  display: none;
}

@media (max-width: 900px) {
  .chart_container {
    padding: 10px;
    height: 100vh;
    padding-bottom: 500px;
  }
  .individual_container {
    background-color: rgba(240, 248, 255, 0.233);
    flex-direction: column;
    height: 150vh;
    padding-bottom: 100px;
    gap: 20px;
  }
  .chart {
    width: 90%;
    height: 300px;
  }

  .table_container {
    display: none;
  }

  .wide_chart {
    display: none;
  }

  .summary {
    width: 100%;
    padding: 10px;
  }

  .chart_mobile {
    display: block;
    width: 90%;
    padding: 10px;
    height: 300px;
  }
}
