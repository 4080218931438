.account_container {
  position: fixed;
  top: 0px;
  right: 0px;
  /* background-color: #ffffffbb; */

  /* width: max(400px, 45vw); */
  width: 100vw;
  height: 100vh;
  border-radius: 20px;
  color: white;
  z-index: 50;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  pointer-events: none;
}

.title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
}

.account_main {
  background-color: #29383f;
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* width: 500px; */
  margin: 90px 20px;
  padding: 50px;
  border-radius: 40px;
  pointer-events: all;
  overflow-y: scroll;
}

.account_main a {
  color: rgb(44, 158, 158);
}

h1 {
  font-size: 1.1rem;
  font-weight: 300;
  margin-bottom: 0px;
}

h2 {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0px;
}

p {
  font-weight: 200;
}

.account_main::-webkit-scrollbar {
  display: none;
}

.account_info {
  display: flex;
  flex-direction: column;
}

.account_row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
  margin-bottom: 10px;
  gap: 20px;
}

.account_row_reset {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
  margin-bottom: 10px;
  gap: 20px;
}

.account_column {
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 10px;
  margin-right: 20px;
}

.account_fixed_width {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  width: 300px;
  gap: 10px;
}

.account_info_button {
  width: 200px;
}

.password_reset_button {
  width: 200px;
}

.account_button {
  width: 200px;
  padding: 15px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  transition: 0.2s ease-in-out;
  background-color: teal;
  color: white;
}

.account_button:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
}

.account_button:disabled {
  background-color: rgb(175, 175, 175);
}

.delete_account {
  width: 200px;
  padding: 15px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  transition: 0.2s ease-in-out;
  background-color: #c35045;
  color: aliceblue;
}

.partition {
  width: 90%;
  margin-bottom: 20px;
  border-color: rgba(255, 255, 255, 0.1);
}

.account_input {
  margin: 0px;
  width: 200px;
  padding: 12px 10px;
  border-radius: 20px;
  font-size: 1.1rem;
  font-weight: 200;
  background-color: rgba(75, 92, 112, 0.5);
  border: 1px solid rgba(63, 78, 95, 0.5);
  outline: 0 !important;
  color: white;
}

.account_input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.account_input:focus {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(63, 78, 95, 0.6);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8) !important;
}

.user_button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  /* border-radius: 50%; */
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
  margin-right: 20px;
  padding: 10px;
  z-index: 40;

  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

.menu_icon {
  width: 30px;
  padding: 5px;
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
}

.icon {
  width: 20px;
  filter: invert(100%);
  margin-bottom: 0px;
}

/* Responsive design */

@media (max-width: 900px) {
  .account_main {
    width: 90%;
  }
  /* .account_row {
    flex-direction: column;
    align-items: center;
    margin: 30px;
    gap: 20px;
  } */
  .account_fixed_width {
    margin: 10px;
  }
  .account_row_reset {
    flex-direction: column;
    align-items: start;
    /* gap: 20px; */
  }
}

/* Side Panel */

.side_panel {
  background-color: rgba(255, 255, 255, 0.205);
  width: 60px;
  margin-right: 20px;
  margin-top: 80px;
}
