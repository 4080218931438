.message_container {
  position: absolute;
  /* background-color: rgba(240, 248, 255, 0.373); */
  width: 100vw;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  overflow: visible;
}

.message {
  position: absolute;
  top: -200px;

  /* background-color: antiquewhite; */
  margin: 20px;
  padding: 15px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 20px;
  animation: message 10s linear;
  box-shadow: black 0px, 3px, 8px;

  color: white;
  font-size: 1rem;
  z-index: 20;

  display: flex;
  align-items: center;
  gap: 20px;
  pointer-events: fill;
  z-index: 40;
}

.message_icon {
  width: 20px;
  filter: invert(100%);
}

@keyframes message {
  0% {
    transform: translateY(0px);
  }

  10%,
  20% {
    transform: translateY(200px);
  }

  80% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 900px) {
  .message_container {
    position: absolute;
    /* background-color: rgba(237, 180, 162, 0.373); */
    width: 100vw;
    height: 90vh;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    pointer-events: none;
    overflow: visible;
  }

  @keyframes message {
    0% {
      transform: translateY(0px);
      opacity: 0;
    }

    5% {
      transform: translateY(300px);
      opacity: 1;
    }

    95% {
      transform: translateY(300px);
      opacity: 1;
    }

    100% {
      transform: translateY(0px);
      opacity: 0;
    }
  }
}
