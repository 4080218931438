body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  z-index: 30;
}

.app {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background: rgb(39,43,55); */
  /* background: linear-gradient(90deg, rgb(49, 62, 75) 0%, rgba(66,70,82,0.8) 100%); */
}

.circle {
  position: relative;
  background-color: aliceblue;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.controls {
  background-color: rgb(206, 206, 206);
  position: fixed;
  left: 50%;
  margin-left: -250px;
  bottom: 300px;
  width: 500px;
  height: 120px;
  border-radius: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.viewport {
  width: 500px;
  height: 300px;
  background-color: aliceblue;
}
