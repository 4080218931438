.map_controls {
  position: absolute;
  background-color: #ffffff;
  z-index: 20;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: black 5px, 5px, 5px, 8px;

  display: flex;
  flex-direction: column;
  align-items: start;
  /* gap: 10px; */
}

.map_controls_closed {
  position: absolute;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  z-index: 20;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: start;
  /* gap: 10px; */
  overflow: hidden;
}

.map_controls_closed:hover {
  background-color: #ececec;
  box-shadow: black 5px, 3px, 8px;
}

.controls_row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
  padding-left: 10px;
  padding: 10px;
}

.controls_column {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  /* gap: 20px; */
}

.controls_title {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0px;
  width: 180px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  gap: 10px;
}

.control_icon {
  width: 30px;
  padding: 5px;
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
}

.controls_hr {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.prompt {
  color: teal;
}

.option {
  margin-bottom: 10px;
  font-size: 0.9rem;
  width: auto;
}

.sub_option {
  margin: 0px;
  padding: 10px;
}

.sub_option:hover {
  background-color: antiquewhite;
}

/* @media (max-width: 900px) {
  .map_controls {
    display: none;
  }
} */
